<template>
  <a-form
      id="components-form-demo-normal-login"
      :form="form"
      class="login-form"
      @submit="handleSubmit"
  >
    <a-form-item>
      <a-input
          v-decorator="[
          'usr',
          { rules: [{ required: true, message: '' }] },
        ]"
          placeholder="邮箱账号"
      >
        <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)"/>
      </a-input>
    </a-form-item>
    <a-form-item>
      <a-input
          v-decorator="[
          'pwd',
          { rules: [{ required: true, message: '' }] },
        ]"
          type="password"
          placeholder="密码"
      >
        <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)"/>
      </a-input>
    </a-form-item>

    <a-form-item style=" margin: 0">
      <div style="display: flex;height: 40px;">
        <img src="/user/captcha" alt="" onclick="this.src=this.src+'?time+'+Math.random()"
             style="border-radius: 10px;border:1px cornflowerblue solid;">
        <a-input
            v-decorator="[
          'checkMa',
          { rules: [{ required: true, message: '' }] },

        ]"
            style="margin-left: 1rem;margin-top: 4px "
            placeholder="验证码"
            :maxLength="5"


        >
        </a-input>
      </div>
    </a-form-item>
    <a-form-item>
      <a class="login-form-forgot" @click="forgetPassword()">
        忘记密码?
      </a>
      <a-button type="primary" html-type="submit" class="login-form-button">
        登 录
      </a-button>

    </a-form-item>


    <a-modal
        title="输入邮箱"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
        :zIndex="9999"
    >
      <a-input
          v-decorator="[
          'forgetMail',
          { rules: [{ required: true, message: '' }] },
        ]"
          placeholder="Mail"
          style="margin-right: 10px"
          v-model="forgetMail"

      >
        <a-icon slot="prefix" type="mail" style="color: rgba(0,0,0,.25)"/>
      </a-input>
    </a-modal>


    <a-modal
        title="输入密码"
        :visible="visibleM"
        :confirm-loading="confirmLoadingM"
        @ok="handleOkM"
        @cancel="handleCancelM"
        :zIndex="9999"
    >
      <div style="display: flex">
        <a-input
            v-decorator="[
          'MailCode',
          { rules: [{ required: true, message: '' }] },
        ]"
            placeholder="邮箱验证码"
            style="margin-right: 30px;margin-left: 10px"
            v-model="mailCode"
            :maxLength="5"
        >
          <a-icon slot="prefix" type="mail" style="color: rgba(0,0,0,.25)"/>
        </a-input>

        <a-input
            v-decorator="[
          'resetPassword',
          { rules: [{ required: true, message: '' }] },
        ]"
            placeholder="新密码"
            style="margin-right: 10px"
            v-model="resetPassword"
            :maxLength="30"
        >
          <a-icon slot="prefix" type="code-sandbox" style="color: rgba(0,0,0,.25)"/>
        </a-input>
      </div>
    </a-modal>

  </a-form>
</template>

<script>
export default {

  name: 'MethodLog',
  beforeCreate() {
    this.form = this.$form.createForm(this, {name: 'normal_login'});
  },
  data() {
    return {
      usr: 0,
      pwd: 0,
      ma: 0,
      code: 0,
      msg: 0,
      access_token: 0,
      data: 0,

      forgetMail: null,
      mailCode: null,
      resetPassword: null,
      visible: false,
      visibleM: false,
      confirmLoading: false,
      confirmLoadingM: false
    }
  },
  methods: {
    handleCancel() {
      this.visible = false;
    },
    handleCancelM() {
      this.visibleM = false;
    },
    handleOk() {
      if (this.forgetMail !== null) {
        this.confirmLoading = true;
        this.$axios.post("/user/ForgotPassword", {
          emailaddr: this.forgetMail
        }).then(() => {
          this.$message.success("验证码已发送，请查收")
          this.visible = false;
          this.visibleM = true;
          this.confirmLoading = false;
        }).catch(err => {
          this.codeCheck("error", err.code, err.msg)
        })
      } else {
        this.codeCheck("error", -1, "请输入邮箱！")
      }


    },
    handleOkM() {
      if (this.mailCode !== null && this.resetPassword !== null) {
        this.confirmLoadingM = true;
        this.$axios.post("/user/ResetPassord", {
          "emailaddr": this.forgetMail,
          "password": this.resetPassword,
          "repassword": this.resetPassword,
          "captcha": this.mailCode
        }).then(() => {
          this.$message.success("密码重置成功！")
          this.visibleM = false;
          this.confirmLoadingM = false;
          this.forgetMail = '';
          this.mailCode = '';
          this.resetPassword = '';
        }).catch(err => {
          this.codeCheck('error', err.code, err.msg);
        })
      } else {
        this.codeCheck("error", -1, "请输入验证码和密码！")
      }

    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values);
          this.usr = values.usr;
          this.pwd = values.pwd;
          this.ma = values.checkMa;

          this.$axios({
            method: 'POST',
            url: '/user/login',
            data: {
              "emailaddr": this.usr,
              "password": this.pwd,
              "captcha": this.ma
            },
          }).then(valuess => {
                this.$message.success('登录成功');
                const temp = JSON.parse(valuess.data.data)
                this.$store.commit('setF5Token', temp.refresh_token)
                this.$store.commit('setUsrRole', temp.user_role)
                this.$store.commit('setAcToken', temp.access_token)
                this.$store.commit('setIsLogin', (Math.random() * 8848) + 1)
                this.$store.commit('setMail', this.usr)
                this.$router.push('/index.html')
              sessionStorage.setItem('wasLogin','1')
              }).catch(err => {
            this.codeCheck('error', err.code, err.msg)
          })
        }
      });
    },

    codeCheck(type, code, msg) {
      this.$notification[type]({
        message: "code: " + code,
        description:
        msg,
      });
      this.confirmLoading = false;
      this.confirmLoadingM = false;
    },

    forgetPassword() {
      this.visible = true
    }
  },


};
</script>

<style scoped>

#components-form-demo-normal-login .login-form {
  max-width: 280px;
}

#components-form-demo-normal-login .login-form-forgot {
  float: right;
}

#components-form-demo-normal-login .login-form-button {
  width: 100%;
}

</style>
