<template>

  <a-form
      id="components-form-demo-normal-login"
      :form="form"
      class="login-form"
      @submit="handleSubmit"
      style="position: relative"
  >
    <a-form-item>
      <div style="display: flex;">

        <a-input
            v-decorator="[
          'userNameReg',
          { rules: [{ required: true, message: '' }] },
        ]"
            placeholder="注册邮箱账号（不需要验证）"
        >
          <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)"/>
        </a-input>


      </div>
    </a-form-item>

    <div style="display: flex;">


      <a-form-item style="width: 50%">
        <a-input
            v-decorator="[
          'passwordReg',
          { rules: [{ required: true, message: '' }] },
        ]"
            type="password"
            placeholder="密码"
            :maxLength="30"

        >
          <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)"/>
        </a-input>
      </a-form-item>

      <a-form-item style="margin-left: 10px;width: 50%">
        <a-input
            v-decorator="[
          'confirmPassword',
          { rules: [{ required: true, message: '' }] },
        ]"
            type="password"
            placeholder="确认密码"
            :maxLength="30"

        >
          <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)"/>
        </a-input>
      </a-form-item>
    </div>

    <a-form-item>
      <div style="display: flex">
        <img src="/user/captcha" alt="" onclick="this.src=this.src+'?time+'+Math.random()"
             style="border-radius: 10px;border:1px cornflowerblue solid;">
        <a-input
            v-decorator="[
          'codeBox',
          { rules: [{ required: true, message: '' }] },
        ]"
            placeholder="图片验证码"
            :maxLength="5"
            style="margin-top: 5px;margin-left: 10px"

        >
          <a-icon slot="prefix" type="code-sandbox" style="color: rgba(0,0,0,.25)"/>
        </a-input>
      </div>
    </a-form-item>


    <a-form-item>

      <a-button :disabled="!visible" type="primary" html-type="submit" class="login-form-button">
        注 册
      </a-button>
    </a-form-item>


<!--    <a-modal-->
<!--        title="邮箱验证"-->
<!--        :visible="visible"-->
<!--        :confirm-loading="confirmLoading"-->
<!--        @ok="handleOk"-->
<!--        @cancel="handleCancel"-->
<!--        :zIndex="9999"-->
<!--    >-->
<!--      <div style="display: flex">-->

<!--        <a-input-->
<!--            v-decorator="[-->
<!--          'mailCode',-->
<!--          { rules: [{ required: true, message: '' }] },-->
<!--        ]"-->
<!--            placeholder="验证码"-->
<!--            style="margin-right: 10px"-->
<!--            v-model="mailCode"-->

<!--        >-->
<!--          <a-icon slot="prefix" type="mail" style="color: rgba(0,0,0,.25)"/>-->
<!--        </a-input>-->

<!--        <a-button type="primary" icon="mail" :disabled="isClick" @click="reMailSuccess()">-->
<!--          发送验证码-->
<!--        </a-button>-->
<!--        <a-button type="primary" shape="circle" v-if="enableTimer" style="margin-left: 10px">-->
<!--          {{ time }}s-->
<!--        </a-button>-->
<!--      </div>-->
<!--    </a-modal>-->


  </a-form>
</template>

<script>
export default {
  name: 'MethodLog',
  beforeCreate() {
    this.form = this.$form.createForm(this, {name: 'normal_login'});
  },
  data() {
    return {
      enableTimer: false,
      time: 30,
      mailCode: '',
      visible: true,
      confirmLoading: false,
      isClick: false,
      nullsa: '',
      emailaddr: 0,
      pwd: 0,
      repassword: 0,
      caotcha: 0,

      code: 0,
      msg: 0,
    }
  },
  methods: {
    handleSubmit(e) {

      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (values.passwordReg !== values.confirmPassword) {
            this.openNotificationWithIcon('error');
            this.form.setFieldsValue({
              passwordReg: '',
              confirmPassword: ''
            })
          } else {
            // console.log('Received values of form: ', values);
            this.$message.loading({content:'注册中',key:1332})
            this.emailaddr = values.userNameReg;
            this.pwd = values.passwordReg;
            this.repassword = values.confirmPassword;
            this.captcha = values.codeBox;

            this.$axios.post("/user/register", {
              "emailaddr": this.emailaddr,
              "password": this.pwd,
              "repassword": this.repassword,
              "captcha": this.captcha
            }).then(() => {
              this.mailSuccess();
              this.visible = false;
              setTimeout(()=>{
                this.confirmLoading = true;
                this.$axios.post("/user/RegistrationVerification", {
                  "emailaddr": this.emailaddr,
                  "captcha": 12345
                }).then(() => {
                  this.$message.success({content:'注册成功,自动跳转到登录页',key:1332})
                  this.confirmLoading = false;
                  setTimeout(() => {
                    this.$router.go(0)
                  }, 500)
                }).catch(err => {
                  this.visible = true;
                  this.codeCheck('error', err.code, err.msg);
                  this.confirmLoading = false;
                },1000)
              })
            }).catch(err => {
              this.codeCheck('error', err.code, err.msg)
            })


          }

        }
      });
    },

    handleOk() {

      this.confirmLoading = true;
      this.$axios.post("/user/RegistrationVerification", {
        "emailaddr": this.emailaddr,
        "captcha": this.mailCode
      }).then(() => {
        this.$message.success('注册成功,自动跳转到登录页');
        this.confirmLoading = false;
        setTimeout(() => {
          this.$router.go(0)
        }, 500)
      }).catch(err => {
        this.codeCheck('error', err.code, err.msg);
        this.confirmLoading = false;
      })

    },
    handleCancel() {
      this.visible = false;
    },

    codeCheck(type, code, msg) {
      this.$notification[type]({
        message: "code: " + code,
        description:
        msg,
      });
    },


    openNotificationWithIcon(type) {
      this.$notification[type]({
        message: 'Error!',
        description:
            '密码不相同,请检查后在输入',
      });
    },


    reMailSuccess() {
      this.$axios.post("/user/RefreshEmailCaptcha", {
        "emailaddr": this.emailaddr
      }).then(() => {
        this.$message.success('验证码已发送');
        this.enableTimer = true;
        this.isClick = true;
        this.timer = setInterval(() => {
          this.time--;
          if (this.time === 0) {
            this.isClick = false;
            this.enableTimer = false;
            this.time = 30;
            clearInterval(this.timer)
          }
        }, 1000)


      }).catch(err => {
        this.codeCheck('error', err.code, err.msg)
      })


    },


    mailSuccess() {
      // this.$message.success('验证码已发送');
      this.enableTimer = true;
      this.isClick = true;
      this.timer = setInterval(() => {
        this.time--;
        if (this.time === 0) {
          this.isClick = false;
          this.enableTimer = false;
          this.time = 30;
          clearInterval(this.timer)
        }
      }, 1000)
    },

  },


};
</script>

<style scoped>

#components-form-demo-normal-login .login-form {
  max-width: 280px;
}

#components-form-demo-normal-login .login-form-forgot {
  float: right;
}

#components-form-demo-normal-login .login-form-button {
  width: 100%;
}

</style>
