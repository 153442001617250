<template>
  <div>
    <a-layout-footer class="footer" style="background-color: rgb(49, 49, 49); display: block;z-index: 8848">
      <div class="footerFr">
        <div class="friendCText"
             style="margin-bottom: 5px; white-space: nowrap; color: gray; overflow: hidden; text-overflow: ellipsis;">
          联系我们: 问题反馈联系微信 yinsu688
        </div>
        <div style="height: 2.4rem; border: 1px solid rgba(0, 0, 0, 0); background-color: rgba(128, 128, 128, 0.3);">
          <div style="margin-top: 5px;">
            <ul class="friendCon">
              <li v-for="(item,i) in links" :key="i" @click="linkGo(item.url)">{{ item.name }}</li>
            </ul>
          </div>
        </div>
        <div class="copyRight">音速电玩 www.yskam.com &nbsp;&nbsp;<a class="gov" href="https://beian.miit.gov.cn/ ">{{
            web_info.foot_info
          }}</a>
<!--          <a href="https://www.51.la/?comId=20884331" title="51.La 网站流量统计系统" target="_blank"><span-->
<!--              style="display:inline-block;background-color:#EF5350;color:#fff;padding:2px 5px;font-family:arial;font-size:12px;font-weight:bold;line-height:1;">51La</span></a>-->
        </div>
      </div>

    </a-layout-footer>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: "infoot",
  data() {
    return {
      links: '',
    }
  },
  methods: {

    linkGo(v) {
      window.open(v)
    }
  },
  computed: {
    ...mapState('footer', ['website_info'])
  },

  created() {
    const Va = this.website_info
    this.web_info = Va.website_info[0]
    console.log(this.web_info)
    this.links = Va.footerLinks
    document.title = this.web_info.website_title
    const metas = document.getElementsByTagName("meta");
    metas[2].content = this.web_info.key_words
    metas[3].content = this.web_info.description
  }
}
</script>

<style scoped>
.gov {
  color: #6b6b6b;
}

.gov:hover {
  color: #0EA9F5;
}

.friendCon li:hover {
  cursor: pointer;
  color: #0EA9F5;
  transform: scale(1.1);
}

@media screen and (min-width: 769px ) {

  .friendCon {
    list-style: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .friendCText {
    font-size: 14px
  }

  .copyRight {
    margin-top: 15px;
    text-align: center;
    color: #6b6b6b
  }

  .footerFr {
    margin: 0 auto;

  }

  .friendCon li {
    display: inline-block;
    margin-left: 10px;
    font-size: 15px;
    color: #9f9d9d;
    transition: all .4s;
  }

}

@media screen and (max-width: 768px) {


  .friendCText {
    font-size: 10px
  }

  .footerFr {
    margin-left: -20px
  }

  .copyRight {
    margin-top: 15px;
    text-align: center;
    color: #6b6b6b;
    font-size: 10px
  }

  .friendCon li {
    display: inline-block;
    margin-left: 10px;
    font-size: 10px;
    color: #9f9d9d;
    transition: all .4s;
  }

}
</style>
