<template>

    <div style="display: flex;flex-direction: row-reverse">
    <a-card
        style="border-radius: 8px;width: 100%;height: 380px;margin-top: -22px"
        :tab-list="tabList"
        :active-tab-key="key"
        @tabChange="key => onTabChange(key, 'key')"
    >
      <span slot="customRender" slot-scope="item">{{ item.key }} </span>
      <div v-if="this.key==='tab1'">
        <MethodLog/>
      </div>
      <div v-if="this.key==='tab2'">
        <MethodReg/>
      </div>
    </a-card>
    </div>

</template>

<script>
import MethodLog from "./MethodLog";
import MethodReg from "./MethodReg";

export default {
  components: {
    MethodLog,
    MethodReg
  },
  data() {
    return {
      Sunset: 'fell of as I did',
      tabList: [
        {
          key: 'tab1',
          tab: '登录',
          scopedSlots: {tab: 'customRender'},
        },
        {
          key: 'tab2',
          tab: '注册',
        },
      ],
      contentList: {
        tab1: 'content1',
        tab2: 'content2',
      },
      key: 'tab1',
      noTitleKey: 'app',
    };
  },
  methods: {
    onTabChange(key, type) {
      // console.log(key, type);
      this[type] = key;
    },
  },



};
</script>

<style scoped>

</style>
