<template>
    <div>
        <a-layout-header
                style="box-shadow: rgba(0, 0, 0, 0.4) 1px 1px 2px 2px; background-color: whitesmoke"
        >
            <!--        backgroundImage: 'url(' + logo + ')',-->
            <!--        backgroundSize: 'cover',-->
            <!--        backgroundPosition: 'center',-->
            <div
                    style="height: 46px;float: left;margin-left: -39px"
                    @click="t"
            >
                <img :src="logo" height="100%" alt="">
            </div>
            <div v-if="!$route.path.includes('/vip')">


                <div
                        class="Log"
                        @click="showModal"
                        v-if="isLogin == 0 || isLogin == undefined || isLogin == null"
                >
                    登录 / 注册
                </div>

                <router-link :to="'/BackEnd'" v-if="parseInt(usrRole) && show">
                    <div style="margin-left: 20px" class="Log">后台管理</div>
                </router-link>

                <a-dropdown v-model="headerPopment" v-if="!show">
                    <a style="margin-right: 20px;transition: all 0.5s;margin-left:20px;float: right;font-size: 13px;cursor:pointer;">
                        菜单
                    </a>
                    <a-menu slot="overlay">
                        <a-menu-item key="8849">
                            <router-link :to="'/BackEnd'" v-if="parseInt(usrRole)">
                                <div>后台管理</div>
                            </router-link>
                        </a-menu-item>


                        <a-menu-item key="8848">
                            <router-link :to="'/personality'" v-if="parseInt(usrRole)">
                                个人中心
                            </router-link>
                        </a-menu-item>


                        <a-menu-item :key="i" @click="hrefIndex(item.url)" v-for="(item,i) in website_info.headerLinks">
                            {{ item.name }}
                        </a-menu-item>

<!--                      <a-menu-item  @click="$router.push('/personality/blackList')" key="8849">-->
<!--                        黑名单-->
<!--                      </a-menu-item>-->
                    </a-menu>
                </a-dropdown>


                <div style="margin-left: 20px" class="Log" v-if="show && isLogin !=0 && isLogin !=undefined ">
                    <router-link :to="'/personality'">
                        个人中心
                    </router-link>
                </div>

                <a style="transition: all 0.5s;float: right;font-size: 15px;cursor:pointer;" title="点击登出"
                   @click="logOut"
                   v-if="isLogin != 0">
                    {{ $store.getters.Mail }}
                </a>


                <div v-if="show" style="margin-left: 20px; margin-right: 20px">
                    <div class="Log" style="margin-right: 20px" @click="hrefIndex(item.url)"
                         v-for="item in website_info.headerLinks" :key="item.id">
                        {{ item.name }}
                    </div>

<!--                  <div class="Log" style="margin-right: 20px" @click="$router.push('/personality/blackList')" key="8849">-->
<!--                    黑名单-->
<!--                  </div>-->


                </div>


                <div
                        style="float:right;margin-right:15px"
                        class="Log"
                        @click="t()"
                        v-if="this.$route.path != '/index.html'"
                >
                    首页
                </div>
            </div>

        </a-layout-header>

        <a-modal title="" :visible.sync="dialogVisible" width="700px" :footer="null" @cancel="dialogVisible =false"
                 :destroyOnClose="true"
                 :header="null" :closable="false" :bodyStyle="{borderRadius:'19px'}" :keyboard="true">
            <log style="margin-top: 25px"></log>
        </a-modal>
    </div>
</template>

<script>
import log from "./logUsr/logBody";
import {mapState} from "vuex";


export default {
    components: {
        log,
    },
    name: "inhead",
    data() {
        return {
            show: false,
            headerPopment: false,
            logo: require("@/assets/logo.png"),
            isLogin: this.$store.getters.isLogin,
            dialogVisible: false,
            usrRole: this.$store.getters.usrRole,
        };
    },
    computed: {
        ...mapState('footer', ['website_info'])
    },
    methods: {
        hrefIndex(url) {
            const a = document.createElement('a')
            a.href = url
            a.target="_blank"
            a.click()
        },

        t() {
            this.$router.push("/index.html");
        },

        logOut() {
            this.$store.commit("setF5Token", "");
            this.$store.commit("setUsrRole", "");
            this.$store.commit("setAcToken", "");
            this.$store.commit("setIsLogin", "0");
            this.$store.commit("setMail", "");
            this.$router.go(0);
        },

        showModal() {
            this.$router.push('/login')
        },

    },
    created() {
        console.log(this.website_info)
        this.show = !(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent))
        if (this.$store.getters.isLogin != -1) {
            this.isLogin = this.$store.getters.Mail;
        }
    },
};
</script>

<style scoped>
.TitleImg {
    height: 55px;
    width: 155px;
    margin-top: 5px;
}

.Log {
    transition: all 0.5s;
    float: right;
    font-size: 15px;
}

.Log:hover {
    cursor: pointer;
    color: #0ea9f5;
    transform: scale(1.1);
}

@media screen and (max-width: 768px) {
    .Log {
        font-size: 10px;
        margin-right: -12px;
    }

    /*.TitleImg {*/
    /*  height: 30px;*/
    /*  width: 88px;*/
    /*  margin-top: 15px;*/
    /*  margin-left: -35px;*/
    /*}*/
}
</style>
