var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{staticClass:"login-form",staticStyle:{"position":"relative"},attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('div',{staticStyle:{"display":"flex"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'userNameReg',
          { rules: [{ required: true, message: '' }] } ]),expression:"[\n          'userNameReg',\n          { rules: [{ required: true, message: '' }] },\n        ]"}],attrs:{"placeholder":"注册邮箱账号（不需要验证）"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1)]),_c('div',{staticStyle:{"display":"flex"}},[_c('a-form-item',{staticStyle:{"width":"50%"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'passwordReg',
          { rules: [{ required: true, message: '' }] } ]),expression:"[\n          'passwordReg',\n          { rules: [{ required: true, message: '' }] },\n        ]"}],attrs:{"type":"password","placeholder":"密码","maxLength":30}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',{staticStyle:{"margin-left":"10px","width":"50%"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'confirmPassword',
          { rules: [{ required: true, message: '' }] } ]),expression:"[\n          'confirmPassword',\n          { rules: [{ required: true, message: '' }] },\n        ]"}],attrs:{"type":"password","placeholder":"确认密码","maxLength":30}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1)],1),_c('a-form-item',[_c('div',{staticStyle:{"display":"flex"}},[_c('img',{staticStyle:{"border-radius":"10px","border":"1px cornflowerblue solid"},attrs:{"src":"/user/captcha","alt":"","onclick":"this.src=this.src+'?time+'+Math.random()"}}),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'codeBox',
          { rules: [{ required: true, message: '' }] } ]),expression:"[\n          'codeBox',\n          { rules: [{ required: true, message: '' }] },\n        ]"}],staticStyle:{"margin-top":"5px","margin-left":"10px"},attrs:{"placeholder":"图片验证码","maxLength":5}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"code-sandbox"},slot:"prefix"})],1)],1)]),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"disabled":!_vm.visible,"type":"primary","html-type":"submit"}},[_vm._v(" 注 册 ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }